import {useEffect, useState} from "react";

export default function Page(props) {
    const [show, setShow] = useState(!!0)
    const [loading, setLoading] = useState(true)
    const [content, setContent] = useState(null)
    const allSongs = 'https://elsampo.fi/lab/roll/feeds/data-all.php'
    useEffect(() => {
        function callback(txt) {
            if (!txt) return
            setContent(txt.toString())
            setLoading(false)
        }
        const infoUpdater = async () => {
            fetch(allSongs)
                .then((response) => response.text().then(callback))
        }
        infoUpdater()

        return () => {
            setContent(null)
            setLoading(false)
        }

    }, [show])

    if (!show) return <button className={"btn btn-page"}
             onClick={() => {
                 setShow(true)
             }}
        >
        <i className={"fa-solid fa-list"}/>
    </button>

    return <div className={"page"}>
        <header>
            <button className={"btn btn-page"
                    + (loading ? " loading" : "")
                }
                onClick={(evt) => {
                    evt.stopPropagation()
                    setContent(null)
                    setShow(false)
                }}
            >
                <i className={"fa-solid fa-times"}/>
            </button>
            <h2>{loading ? 'Loading...' : 'Latest playlist'}</h2>
        </header>
        <section dangerouslySetInnerHTML={{ __html: content ? content: null}} />
        <footer>
            <a
                className={"btn"}
                href={"https://biisit.info/soittolista/rollfm"}
                target={"_blank"}
                rel={"noopener noreferrer"}
            ><i className={"fa-solid fa-arrow-up-right-from-square"}/>Biisit.info</a>
        </footer>
    </div>
}