import {useEffect, useRef, useState} from "react";
import Page from "./Page";
import './App.scss';
const STORAGE_ITEMS = "rollSongsSaved"
function App() {
  const _initState = {
    isLoading: true,
    isRepeating: false,
    isPlaying: false,
    ticker: true,
    info: null,
    delay: 0,
    showPage: true
  }

  const [isLoading, setIsLoading] = useState(_initState.isLoading)
  const [isRepeating, setIsRepeating] = useState(_initState.isRepeating)
  const [isPlaying, setIsPlaying] = useState(_initState.isPlaying)
  const [ticker, setTicker] = useState(_initState.ticker)
  const [info, setInfo] = useState(_initState.info)
  const [delay, setDelay] = useState(_initState.delay)
  // const [errors, setErrors] = useState(0)

  const currentSong = 'https://elsampo.fi/lab/roll/feeds/data.php'
  const streamUrl = 'https://rollfm.online'
  //const streamUrl = 'https://jarviradio.radiotaajuus.fi:9000/jr'
  const audioRef = useRef()
  const sourceRef = useRef()

  useEffect(() => {
    let _timer = {}
      function callback(txt) {
        if(!txt) return
        _timer = setTimeout(() => {
          setInfo(txt)
          setTicker(!ticker)
          setIsLoading(false)
          setDelay(3000)
        }, delay)
      }

      const infoUpdater = async () => {
        fetch(currentSong)
            .then((response) => response.text().then(callback))
      }

      infoUpdater()

      callback()
      return () => {
        _timer = clearTimeout(_timer)
      }
  }, [ticker, delay])

  useEffect(()=>{
    audioRef.current.src = !isPlaying ? "" : streamUrl
    //console.dir(sourceRef.current)
    //console.dir(audioRef.current.textTracks)
    // if(!isPlaying) {
    //   resetPlayer()
    // }
    // if(audioRef.current.error){
    //   setIsPlaying(true)
    //   setErrors(errors + 1)
    // }
  }, [isPlaying])


  function resetPlayer(){
    setIsLoading(_initState.isLoading)
    setIsPlaying(_initState.isPlaying)
    setTicker(_initState.ticker)
    setInfo(_initState.info)
    setDelay(_initState.delay)
  }

  function renderPlayButton(){
    if(!audioRef.current) return
    // if(audioRef.current.error != null) {
    //   return <span>Retrying...</span>
    // }
    if(audioRef.current.readyState < 3 && isLoading && isPlaying) return <span>buffering...</span>
    return <button
        onClick={()=> {
          setIsRepeating(!isPlaying)
          setIsPlaying(!isPlaying)
        }}
        className={"btn xl"
          + (isPlaying && isRepeating ? " playing" : "")
          + (isPlaying && !isRepeating ? " loading" : "")
        }
      ><i className={"fa-solid fa-" + (isPlaying ? "stop" : "radio")}/>
    </button>
  }

  function renderElement() {
    // //if(audioRef.current?.src === "") return null
    //   audioRef.current.addEventListener("error", () => {
    //     console.error(`Error loading: ${audioRef.current.src}`)
    //     resetPlayer()
    //   })
    return <div>
      <audio ref={audioRef}
        controls={false}
        autoPlay={isPlaying}
        onCanPlay={() => setIsRepeating(true)}
        src={streamUrl}
        // onPlaying={(evt)=> {
        //     console.log(evt)
        // }}
        //onLoadedMetadata={event => console.dir(event.target)}
      >
      <source ref={sourceRef} src={streamUrl || ""} type="audio/wav"/>
        Selaimesi ei tue audiota
      </audio>
    </div>
  }

  return <div id={"player"} className={isRepeating ? "playing" : null}>
    <div>
      <header className={"btn-set"}>
        {info === "" && !isLoading ? <button
            onClick={() => {
              resetPlayer()
              setIsPlaying(true)
            }}
            className={"btn xl"}
        ><i className="fa-solid fa-rotate"/></button> : renderPlayButton()}
        <Page />
      </header>
      {renderElement()}
      <p className={"msg"} onClick={()=> {
                setInfo('Loading....')
                setTicker(!ticker)
            }}
          >
          {isRepeating ? <i className="fa-solid fa-music fa-2x" /> : null}{isLoading && !info ? "Rolling on..." : info}</p>
      <SavedItems info={info} stop={() => setIsPlaying(false)}/>
    </div>

  </div>
}

export default App


function SavedItems(props) {
  const [items, setItems] = useState(JSON.parse(localStorage.getItem(STORAGE_ITEMS)) || [])

  useEffect(() => {
    localStorage.setItem(STORAGE_ITEMS, JSON.stringify(items))
  }, [items])
  function renderSavedItems() {
    if (items.length < 1) return null
    const arr = [...items].reverse()
    return <ul>
      {arr.map((item, i) => {
        return <li
            key={i}
            onClick={() => {
              props.stop()
              window.open("http://www.youtube.com/results?search_query=" + item.toString())// target={"_yt"} rel={"noopener noreferrer"}
            }}
            className={item === props.info ? "active" : null}
        >
       <i className="fa-brands fa-youtube fa-2x"/>
          <p>{item}</p>
          <button
              onClick={evt => {
                evt.stopPropagation()
                if (window.confirm("Delete?")) {
                  let itemsList = [...items].filter(itm => {
                    return itm !== item
                  })
                  setItems(itemsList)
                }
              }}
              className={"btn btn-2nd"}
          ><i className="fa-solid fa-minus"/>
          </button>
        </li>
      })}
    </ul>
  }

  function renderAddButton() {
    const isAlready = items.includes(props.info)
    return <div className={"btn-set"}>
      <button
          onClick={() => {
            let itemsList = [...items]
            if (!isAlready) itemsList.push(props.info)
            else return
            setItems(itemsList)
          }}
          className={"btn lg" + (isAlready ? " ok" : "")}
      >
        <i className={"fa-solid fa-" + (isAlready ? "thumbs-up" : "heart")}/>
        <span>{isAlready ? 'ROLLS!' : 'Save for later'}</span>
      </button>
    </div>
  }

  return <section>
    {props.info !== "" ? renderAddButton(): null}
    {renderSavedItems()}
  </section>
}